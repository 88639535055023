import $ from 'jquery';
import 'slick-carousel';
import 'bootstrap';

import '../styles/index.scss';

('use strict');

var affLink =
    'https://chrome.google.com/webstore/detail/yahoo-finance-new-tab/doojmkhhplhicnghmafjbhncmgjiohma';
var height = 750;
var width = 1000;
var windowLeft = window.screenLeft ? window.screenLeft : window.screenX;
var windowTop = window.screenTop ? window.screenTop : window.screenY;
var left = windowLeft + (window.innerWidth / 2) - (width / 2);
var top = windowTop + (window.innerHeight / 2) - (height / 2);
var modalLeft = left + width + 580 + 'px';
var modalTop = top + height + 520 + 'px';
var params =
    'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=' +
    width +
    ',height=' +
    height +
    ',top=' +
    top +
    ',left=' +
    left;
var focusstate;


$(document).ready(function() {
    $('.slider')
        .slick({
            dots: true,
        })
        .css('opacity', 1);

    $('.storeLink').click(function() {
        focusstate = 'store';
        window.open(affLink, 'storePage', params);
    });

    $('.storeDirections')
        .css('left', modalLeft)
        .css('top', modalTop);

    $(window).blur(function() {
        if (focusstate === 'store') {
            $('#storeDirectionsModal').modal('show');
            $('#confirmationModal').modal('hide');
            focusstate = 'page';
        }
    });

    $(window).focus(function() {
        if (focusstate === 'page') {
            $('#storeDirectionsModal').modal('hide');
            $('#confirmationModal').modal('show');
            focusstate = 'store';
        }
    });
});
